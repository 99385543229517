import React, { PropsWithChildren } from "react"

import { BoxProps } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/react"

import styles from "./styles.module.css"

interface PageSectionProps extends BoxProps {
  expandable?: boolean
}

export const PageSection  = ({
  children,
  expandable = false,
  ...rest
}: PropsWithChildren<PageSectionProps>) => {

  return (
    <Box
      width="100%"
      className={styles.pageSection}
      {...rest}
      maxHeight={["auto", expandable ? "auto" : "100vh"]}
      overflow="hidden"
    >
      {children}
    </Box>
  )
}
