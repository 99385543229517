import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

export const Image = ({ name }: { name: string }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      claire: file(relativePath: { eq: "claire.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ampersand: file(relativePath: { eq: "littleLieDown.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      isaac: file(relativePath: { eq: "isaac.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boomtown: file(relativePath: { eq: "boomtown.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      windyCuddle: file(relativePath: { eq: "windyCuddle.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      map: file(relativePath: { eq: "map.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kisses: file(relativePath: { eq: "kisses.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  try {
    switch (name) {
      case "claire":
        return (
          <Img
            fluid={data.claire.childImageSharp.fluid}
            loading="eager"
            critical
            style={{ height: "100%" }}
          />
        )
      case "ampersand":
        return (
          <Img
            fluid={data.ampersand.childImageSharp.fluid}
            loading="eager"
            critical
            style={{ height: "100%" }}
          />
        )
      case "isaac":
        return (
          <Img
            fluid={data.isaac.childImageSharp.fluid}
            loading="eager"
            critical
            style={{ height: "100%" }}
          />
        )
      case "boomtown":
        return (
          <Img
            fluid={data.boomtown.childImageSharp.fluid}
            loading="eager"
            critical
            style={{ height: "100%" }}
          />
        )
      case "windyCuddle":
        return (
          <Img
            fluid={data.windyCuddle.childImageSharp.fluid}
            loading="eager"
            critical
            style={{ height: "100%" }}
          />
        )
      case "map":
        return (
          <Img
            fluid={data.map.childImageSharp.fluid}
            loading="eager"
            critical
            style={{ height: "100%" }}
          />
        )
      case "kisses":
        return (
          <Img
            fluid={data.kisses.childImageSharp.fluid}
            loading="eager"
            critical
            style={{ height: "100%" }}
          />
        )
      default:
        return <div>Picture not found</div>
    }
  } catch {
    return <div />
  }
}
